import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

import footerStyle from './footer.module.scss'

const Footer = () => {

    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                author,
                year
            }
        }
    }
    `)
    return (
        <footer>
            <div className={footerStyle.footer}>
                <p>Created by {data.site.siteMetadata.author}, &copy;{data.site.siteMetadata.year}</p>
            </div>
        </footer>
    )
}

export default Footer