import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import '../styles/index.scss';
import Footer from './footer';
import Header from './header';
import layoutStyle from './layout.module.scss';

const theme = createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true, // No more ripple
        },
    },
    palette: {
        primary: {
            main: '#ef8e38'
        },
        secondary: {
            main: '#2a2b75'
        },
    }
});

const Layout = (props) => {
    return (
        <div className={layoutStyle.container}>
            <Header />
            <ThemeProvider theme={theme}>
                <div className={layoutStyle.content}>
                    {props.children}
                </div>
            </ThemeProvider>
            <Footer />
        </div >
    )
}

export default Layout