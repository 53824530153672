import { Link } from 'gatsby';
import React from 'react';
import headerStyle from './header.module.scss';


const Header = () => {
    return (
        <header>
            <div className={headerStyle.header}>
                <nav>
                    <ul className={headerStyle.navList}>
                        <li>
                            <Link className={headerStyle.navItem} activeClassName={headerStyle.activeNavItem} to='/'>Home</Link>
                        </li>
                        {/* <li>
                            <Link className={headerStyle.navItem} activeClassName={headerStyle.activeNavItem} to='/blog'>Blog</Link>
                        </li> */}
                        <li>
                            <Link className={headerStyle.navItem} activeClassName={headerStyle.activeNavItem} to='/about'>About</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header;