import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import Head from '../components/head'


export const query = graphql`
    query($slug: String) {
        contentfulBlogPost (slug: {eq: $slug}) {
            title,
            publishedDate(formatString: "MMMM Do, YYYY"),
            body {
                json
            }
        }
    }
`
const Blog = ({ data: { contentfulBlogPost } }) => {
    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {

                const { title, file, description } = node.data.target.fields
                return (
                    <figure>
                        <img alt={title['en-US']} src={file['en-US'].url} title={title['en-US']} />
                        <figcaption>{description['en-US']}</figcaption>
                    </figure>
                )
            }
        }
    }
    return (
        <Layout>
            <Head title={contentfulBlogPost.title} />
            <h1>{contentfulBlogPost.title}</h1>
            <p>{contentfulBlogPost.publishedDate}</p>
            {documentToReactComponents(contentfulBlogPost.body.json, options)}
        </Layout>
    )
}

export default Blog